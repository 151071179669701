@media screen and (min-width: 40em) {
	.testimonial:first-child {
		position: absolute;
		top: 59%;
		right: 196%;
		font-family: "Lato";
	}

	.testimonial:last-child {
		position: absolute;
		bottom: 56%;
		left: 195%;
		font-family: "Lato";
	}
	.testimonial {
		display: flex;
		justify-content: center;
		/* align-items: center; */
		width: 100px;
		height: 100px;
		background-color: #e9e9e9;
		border-radius: 50%;
		opacity: 0.5;
		transition: all 0.3s;
		cursor: pointer;
		font-family: "Lato";
	}
	.next-button {
		background-color: #4446d0;
		color: #fff;
		border: none;
		padding: 10px 30px;
		border-radius: 12px;
		border: 1px solid;
		margin-top: 10px;
		cursor: pointer;
		font-family: "Lato", sans-serif;
	}

	.testimonial.active {
		display: flex;
		justify-content: center;
		/* align-items: center; */
		opacity: 1;
		width: 266px;
		height: 232px;
		z-index: 2;
		font-family: "Lato";
	}

	.testimonial-container {
		position: relative;
		display: flex;
		/* gap: 80px; */
		justify-content: center;
		align-items: center;
		height: 400px;
		font-family: "Lato";
	}
	.testimonial.active .testimonial-text {
		visibility: visible;
		font-size: 8px;
		line-height: 1.3em;
		text-align: center;
		margin: 0;
		font-family: "Lato";
	}
	.testimonial .testimonial-text {
		visibility: hidden;
		font-family: "Lato";
	}
	.testimonial.active {
		display: flex;
		justify-content: center;
		/* align-items: center; */
		opacity: 1;
		width: 266px;
		height: 232px;
		z-index: 2;
		font-family: "Lato";
	}
	.footer {
		display: flex;
		justify-content: center;
		padding-inline: 50px;
		padding-block-start: 30px;
		align-items: center;
		gap: 80px;
		font-family: "Lato";
	}
	.testimonial-slider {
		/* visibility: hidden; */
		margin-block-start: 87px;
		margin-block-end: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: "Lato";
		/* background: #edece848; */
		/* background: #ffffeb; */
		padding-block: 30px;
	}

	.testimonial .testimonial-content {
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 10px;
		font-family: "Lato";
	}

	.testimonial .testimonial-image img {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		object-fit: cover;
	}

	.testimonial .testimonial-name {
		margin-top: 1px;
		margin-bottom: 5px;
		font-weight: bold;
		font-family: "Lato";
	}

	.next-button:hover {
		background-color: #4446d0;
		font-family: "Lato";
	}
}

@media screen and (max-width: 40em) {
	.testimonial-slider {
		/* visibility: hidden; */
		/* margin-block-start: 87px; */
		/* margin-block-end: 50px; */
		display: none;
		flex-direction: column;
		align-items: center;
		font-family: "Lato";
		/* background: #edece848; */
		background: #ffffeb;
		/* padding-block: 30px; */
	}

	.testimonial .testimonial-content {
		visibility: hidden;
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 10px;
		font-family: "Lato";
	}

	.testimonial .testimonial-image img {
		visibility: hidden;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		object-fit: cover;
	}

	.testimonial .testimonial-name {
		visibility: hidden;
		margin-top: 1px;
		margin-bottom: 5px;
		font-weight: bold;
		font-family: "Lato";
	}

	.next-button:hover {
		visibility: hidden;
		background-color: #4446d0;
		font-family: "Lato";
	}

	.testimonial:last-child {
		visibility: hidden;
		/* position: absolute; */
		/* bottom: 56%; */
		/* left: 195%; */
		font-family: "Lato";
	}
	/* .testimonial:first-child { */
	/* position: absolute; */
	/* top: 51%; */
	/* right: 10%; */
	/* } */
	.next-button {
		visibility: hidden;
		background-color: #4446d0;
		color: #fff;
		border: none;
		padding: 10px 30px;
		border-radius: 12px;
		margin-top: 10px;
		cursor: pointer;
		font-family: "Lato", sans-serif;
		/* margin-block: 60px; */
	}
	.testimonial-container {
		visibility: hidden;
		/* position: relative; */
		display: flex;
		/* flex-direction: column; */
		/* gap: 80px; */
		justify-content: center;
		align-items: center;
		/* height: 400px; */
		font-family: "Lato";
	}

	.testimonial .testimonial-text {
		visibility: hidden;
		/* visibility: hidden; */
		font-size: 10px;
		padding-inline: 5px;
		font-family: "Lato";
	}

	.testimonial.active .testimonial-text {
		visibility: hidden;
		/* visibility: visible; */
		font-size: 8px;
		line-height: 1.1em;
		text-align: center;
		margin: 0;
		font-family: "Lato";
	}
	.testimonial.active {
		visibility: hidden;
		display: flex;
		justify-content: center;
		/* align-items: center; */
		opacity: 1;
		width: 266px;
		height: 232px;
		z-index: 2;
		font-family: "Lato";
		margin-block: 20px;
	}

	.footer {
		display: flex;
		justify-content: center;
		padding-inline: 10px;
		padding-block-start: 30px;
		align-items: center;
		/* gap: 80px; */
		font-family: "Lato";
		font-size: 12px;
	}
}

/* .testimonial-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 1000px;
}

.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.testimonial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background-color: #e9e9e9;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.testimonial.active {
  opacity: 1;
}

.testimonial-content {
  text-align: center;
  padding: 10px;
}

.testimonial-image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-text {
  font-size: 14px;
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
}

.next-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.next-button:hover {
  background-color: #555;
} */

/* 
.testimonial-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #e9e9e9;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.testimonial.active {
  opacity: 1;
}

.testimonial-content {
  text-align: center;
  padding: 10px;
}

.testimonial-text {
  font-size: 14px;
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.prev-button,
.next-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.prev-button:hover,
.next-button:hover {
  background-color: #555;
} */

/* .demo{ background: #f8f7f6; }

#testimonial-slider{
    padding: 50px 20px 35px 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 115px 19px rgba(236, 236, 236, 1);
}
.testimonial .description{
    font-size: 14px;
    color: #777;
    line-height: 26px;
    text-indent: 30px;
    position: relative;
}
.testimonial .description:before{
    content: "\f10d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: 0;
    left: -15px;
}
.testimonial .title{
    font-size: 24px;
    font-weight: bold;
    color: #363636;
    text-transform: capitalize;
    margin: 0;
}
.testimonial .post{
    display: block;
    font-size: 15px;
    color: #363636;
}
.owl-buttons{
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 40%;
    left: 0;
}
.owl-prev,
.owl-next{
    position: absolute;
    left: 0;
    transition: all 0.4s ease-in-out 0s;
}
.owl-next{
    left: auto;
    right: 0;
}
.owl-buttons .owl-prev:before,
.owl-buttons .owl-next:before{
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 60px;
    font-weight: 900;
    color: #cacaca;
    line-height: 20px;
    opacity: 0.8;
}
.owl-buttons .owl-next:before{
    content: "\f105";
}
.owl-buttons .owl-prev:hover:before,
.owl-buttons .owl-next:hover:before{
    opacity: 1;
}
.owl-theme .owl-controls .owl-buttons div{
    background: transparent;
}
.owl-theme .owl-controls{
    margin-top: 40px;
}
.owl-theme .owl-controls .owl-page span{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #ccc;
    background: url("../images/img-1.jpg") no-repeat;
    background-size: cover;
    transition: all 0.3s ease-in-out 0s;
}
.owl-theme .owl-controls .owl-page:nth-child(2) span{
    background: url("../Image/Vector.png") no-repeat;
    background-size: cover;
}
.owl-theme .owl-controls .owl-page:nth-child(3) span{
    background: url("../Image/software.png") no-repeat;
    background-size: cover;
}
.owl-theme .owl-controls.clickable .owl-page:hover span{
    opacity: 0.5;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls .owl-page.active:hover span{
    border-color: #d133ff;
    opacity: 1;
}
@media only screen and (max-width: 479px){
    .owl-buttons{ bottom: 30%; }} */
