@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Lato", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html {
	overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Lato", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	overflow-y: scroll;
}

.nav-list.open {
	overflow: hidden;
}
.max-container {
	@apply mx-auto max-w-[1440px];
}
code {
	/* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */

	font-family: "Lato", sans-serif;
}

/* .p-btn{
  margin: auto 0;
} */
